@import "./../../../../SCSS/Mixins.scss";

.navbar {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    padding: 8px 12px;
    background: $white;
    border-bottom: 1px solid #dee2e6 !important;
    z-index: 99;
    min-height: 66px;

    ul {
        display: flex;
        gap: 24px;
        align-items: center;

        li {
            a {
                @include fontSize(16px);
                line-height: normal;
                color: $black;
                font-weight: 400;
                position: relative;
                display: inline-block;
                padding: 8px 0px;

                &:hover {
                    color: $primary;
                    opacity: 1;
                }
            }
        }
    }
}


.fullPagePopup {
    .closePopup {
        position: relative;
        min-width: 20px;
        padding: 0px;
        height: 30px;
        width: 30px;
        display: inline-block;
        border: 1px dashed $primary;

        &:before {
            background: $primary;
            content: "";
            @include position(absolute, $left: 13px, $top: 6px);
            transform: rotate(45deg);
            height: 16px;
            width: 2px;
            transition: transform 0.3s ease-in-out;
        }

        &:after {
            @extend :before;
            transform: rotate(-45deg);
        }

        &:hover {
            &:before {
                transform: rotate(90deg);
                background: $black;
            }

            &:after {
                transform: rotate(-90deg);
                background: $black;
            }
        }
    }

    .content-sec {
        img {
            max-width: 250px;
            width: 100%;
        }
    }
}