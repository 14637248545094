@import "./../../../../SCSS/Mixins.scss";

.mfooter {
    .top-sec {
        background: $primary;
        padding-top: 24px;
        padding-bottom: 24px;

        .irdai-box {
            margin-top: 50px;

            img {
                height: 50px;
                margin-bottom: 8px;
            }

            p {
                color: $white;
                font-size: 13px;

                span {
                    font-weight: 700;
                }
            }
        }

        h5 {
            color: $white;
            font-weight: 500;
        }

        hr {
            border: none;
            border-top: 1px dashed $white;
            margin: 16px 0;
        }

        ul {
            display: flex;
            flex-direction: column;
            gap: 5px;

            li {
                a {
                    display: inline-block;
                    padding: 4px 0px;
                    color: $white;
                    font-family: 'Roboto', 'sans-serif';

                    // &:hover {
                    //     color: $secondary;
                    // }
                }

                p {
                    color: $white;

                    span {
                        font-weight: 600;
                        color: rgba(51, 51, 51, 1);
                    }

                    a {
                        color: rgba(51, 51, 51, 1);
                        font-weight: 600;
                        word-break: break-all;
                    }
                }

                .social-icons {
                    display: flex;
                    gap: 16px;

                    a {
                        display: inline-block;

                        img {
                            width: 22px;
                            height: 22px;
                            transition: transform 1s ease-in-out;

                            &:hover {
                                transform: rotate(720deg) scale(1.5);
                                transition: transform 1s ease-in-out;
                            }
                        }
                    }
                }
            }

        }

        .social-icons {
            display: flex;
            gap: 16px;

            a {
                display: inline-block;

                svg {

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }

        .lightOrange-box {
            background: #FFA552;
            background: rgba(255, 255, 255, 0.1);
            ;
            padding: 16px 0px;

            p {
                font-size: 10px;
                line-height: 14px;
                text-align: center;
                color: $white;
                font-weight: 400;
            }
        }
    }
}