@import "../../../SCSS/Mixins.scss";

%button {

    // width: 100%;
    .MuiButton-root {
        box-shadow: none;
        border-radius: 8px;
        font-weight: 400;
        font-family: "Roboto", sans-serif;
        text-transform: inherit;
        padding: 8px 16px;
        @include fontSize(15px);
        letter-spacing: 0px;
        line-height: 24px;
        border: none;
        cursor: pointer;

        @keyframes rotate {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(360deg);
            }
        }
    }

    &.small {
        .MuiButton-root {
            padding: 6px 12px;
            @include fontSize(15px);
            line-height: normal;

            svg {
                height: 16px;
                width: 16px;
            }
        }

        &.loading {
            .MuiButton-root {
                background-color: rgba($primary, 0.3);
                padding: 2px 12px;
            }
        }
    }

    &.large {
        .MuiButton-root {
            padding: 12px 24px;
            @include fontSize(18px);
        }

        &.loading {
            .MuiButton-root {
                background-color: rgba($primary, 0.3);
                padding: 10px 24px;
            }
        }
    }

    &.extraLarge {
        .MuiButton-root {
            padding: 16px 32px;
            @include fontSize(24px);
            line-height: 32px;

            img {
                height: 48px;
            }
        }

        &.loading {
            .MuiButton-root {
                background-color: rgba($primary, 0.3);
                padding: 14px 32px;
            }
        }
    }

    &.disabled {
        .MuiButton-root {
            opacity: 0.3;
        }
    }
}

.primaryBtn {
    @extend %button;

    .MuiButton-root {
        background-color: $primary;
        color: $white;
        outline: 1px solid $primary;

        svg {
            fill: $white ;
        }

        &:hover {
            box-shadow: none;
            background-color: rgba($primary, 0.7);
            color: $white;

            svg {
                fill: #644920;
            }
        }

        // &:focus {
        //     box-shadow: none;
        //     background-color: transparent;
        //     color: $primary;
        // }
        &:active {
            box-shadow: none;
            background-color: transparent;
            color: $primary
        }
    }
}

.secondaryBtn {
    @extend %button;

    .MuiButton-root {
        background-color: $lightRed;
        color: $primary;
        outline: 1px solid $primary;
        font-weight: 400;

        svg {
            fill: $primary;
            width: 18px;
        }

        &:hover {
            background-color: $white;
            box-shadow: none;
            border: none;
            color: $primary;

            svg {
                fill: $primary;
            }
        }

        // &:focus {
        //     box-shadow: none;
        //     border: none;
        //     background-color: transparent;
        //     color: $primary;
        // }
        &:active {
            box-shadow: none;
            border: none;
            background-color: rgba($primary, 0.3);
        }
    }
}

.tertiaryBtn {
    @extend %button;

    .MuiButton-root {
        color: $primary;
        border-radius: 12px;
        padding-left: 0px !important;
        padding-right: 0px !important;

        &:hover {
            background-color: transparent;
            box-shadow: none;
            color: $primary;
        }

        &:focus {
            box-shadow: none;
            background-color: transparent;
            outline: 4px solid $primary;
            color: $primary;
        }

        &:active {
            box-shadow: none;
            outline: none;
            background-color: transparent;
            color: #644920;
        }
    }
}