@import "./../../../SCSS/Mixins.scss";

.mhomepage-wrapper {
    padding-top: 50px;

    h4 {
        span {
            font-weight: 500;
        }
    }

    .mproduct-section {
        padding-top: 30px;

        h1 {
            font-weight: 600;
            font-size: 30px;
            line-height: 36px;
        }

        p {
            color: $grey;
            font-weight: 400;
        }

        .mproduct-sec-inner {
            min-height: 550px;
            background-image: url('../../../../public/images/headbg_mobile.svg');
            background-position: center bottom;
            background-repeat: no-repeat;
            background-size: 100%;
            margin: auto;

            ul {
                display: flex;
                flex-wrap: wrap;
                gap: 12px;

                li {
                    width: calc(50% - 6px);
                    text-align: right;
                }
            }
        }

        .ip_box {
            padding: 70px 6px 12px;
            border-radius: 8px;
            text-align: center;
            max-width: 115px;
            min-height: 120px;
            background: $white;
            @include border-radius(8px);
            @include box-shadow(0px 8px 20px rgba($black, 0.12));
            @include fontSize(15px);
            cursor: pointer;

            p {
                font-weight: 500;
                color: #000 !important;

                &:hover {
                    color: #0056b3;
                }
            }

        }

        $othericons: terminsurance, healthinsurance, carinsurance, bikeinsurance;
        $k: 0;

        @each $icons in $othericons {
            $bacRightP: $k * 70;
            $k: $k +1;

            .#{$icons} {
                position: relative;

                &:before {
                    background: url("../../../../public/images/productIcons.svg") no-repeat -#{$bacRightP}px 0px/280px;
                    content: "";
                    @include position(absolute, $left: calc(50% - 35px), $top: 0px);
                    text-align: center;
                    overflow: hidden;
                    height: 70px;
                    width: 70px;
                }

                span {
                    display: block;
                }

            }
        }
    }

    .mwhy-section {
        ul {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 16px;

            .featureBox {
                width: 100%;
                @include border-radius(8px);
                box-shadow: 0px 0px 6px rgba(13, 25, 99, 0.11);
                padding: 12px;
                text-align: center;

                h5 {
                    line-height: normal;
                    font-weight: 600;
                    margin-top: 12px;
                    margin-bottom: 8px;
                }

                img {
                    max-height: 120px;
                }
            }
        }
    }

    .minsured-section {
        .worklist-list {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 12px;

            li {
                width: 100%;
                @include border-radius(8px);
                text-align: center;
                flex: 1;
                padding-top: 30px;
                @include fontSize(18px);
                line-height: 27px;
                font-weight: 500;
                position: relative;

                &:before {
                    content: '';
                    @include position(absolute, $top: 0px, $left: calc(50% - 15px));
                    height: 30px;
                    width: 30px;
                    background: url("../../../../public/images/listicon.svg") no-repeat 0px 0px/100%;

                }

                span {
                    display: block;
                    @include fontSize(14px);
                    line-height: 21px;
                    font-weight: 400;
                }
            }
        }

        .insured-bg {
            margin-top: 40px;
            text-align: center;

            img {
                width: 100%;
                max-width: 334px;
            }
        }
    }

    .mpartners-section {
        .partners-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 12px;

            li {
                background: rgba(0, 0, 0, 0.1);
                border-radius: 10px;
                padding: 5px;
                background: rgba(238, 238, 238, 1);
                display: flex;
                justify-content: center;
                width: calc((100% / 4) - 9px);

                img {
                    height: 40px;
                }
            }
        }
    }

    .msubscribe-section {
        .query-box {
            display: flex;
            gap: 8px;

            .msubscribebox {
                // max-width: 400px;
                width: 100%;
                background: rgba($secondary, 0.2);
                @include border-radius(8px !important);
                height: 60px;

                .inputField {
                    padding: 6px 12px;
                    background: transparent;
                    @include fontSize(18px);
                    height: 60px;
                    color: $secondary;
                    border: none;
                    outline: none;
                }

            }

            .button_box {
                @include border-radius(8px !important);

                .btn {
                    background: $primary;
                    @include border-radius(8px);
                    color: $white;
                    @include fontSize(18px);
                    padding: 15px 25px;
                    border: none;
                    height: 100%;

                    &:hover {
                        background: #ffffff;
                        color: $primary;
                        outline: 1px solid $primary;
                        outline-offset: -1px;
                    }
                }
            }
        }

        .mcontactbox {
            position: relative;
            height: 220px;

            .supportBox {
                position: absolute;
                right: 12px;
                background: #f2f2f2;
                box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
                border-radius: 10px;
                text-align: left;
                padding: 10px;
                display: inline-block;

                span {
                    display: inline-block;

                    svg {
                        color: $primary;
                    }
                }

                h6 {
                    font-weight: 500;
                }

                a {
                    font-size: 14px;
                    color: $black;

                    &:hover {
                        color: $secondary;
                        opacity: 1;
                    }
                }

                &.email {
                    top: 10px;
                    transform: rotate(-10deg);
                }

                &.call {
                    bottom: 0;
                    transform: rotate(10deg);
                    left: 30%;
                    right: auto !important;
                }
            }
        }
    }
}

// form popup checkbox
.popup-Checkbox {
    .checkbox {
        justify-content: center;

        .MuiTypography-root {
            font-size: 12px;
            color: $black;

            span {
                color: $primary;
            }
        }
    }
}