@import "./Mixins.scss";

.cmsWrapper {
    // padding-top: 90px;

    &.res-wrapper {
        @media(max-width:900px) {
            padding-top: 66px;
        }
    }

    p {
        font-size: 16px;
        line-height: 24px;
        color: #212529;

        span {
            font-weight: 700;
        }
    }

    h3 {
        font-size: 28px;
    }

    .aboutTop-sec {
        img {
            max-width: 300px;
            width: 100%;
        }
    }

    .who-sec {
        .whoweBox {
            background: rgb(238, 238, 238);
            padding: 10px;
            border-radius: 10px;
            text-align: center;
            width: 130px;

            h2 {
                font-weight: 400;
                color: $primary;
                text-transform: uppercase;
            }

        }

        p {
            text-align: justify;
        }
    }

    .vision-valueBox {
        .greyBox {
            text-align: justify;
            padding: 30px 20px;
            background: rgb(234, 234, 234);
            border-radius: 16px;
        }

        .vision-img {
            width: 100%;
            max-width: 180px;
        }
    }

    .asset_li {
        li {
            position: relative;
            padding-left: 24px;
            @include fontSize(15px);
            line-height: 22px;

            &::before {
                @include position(absolute, $top: 8px, $left: 10px);
                height: 6px;
                width: 6px;
                background: $primary;
                border-radius: 50%;
                content: '';
            }
        }
    }

    .humancapital_valueSec {
        .shadowBox {
            box-shadow: 0 4px 16px 0px rgba(0, 0, 0, 0.15) !important;
            border-radius: 10px;
            padding: 20px 10px;
            text-align: center;
            margin-top: 15px;

            img {
                width: 100%;
                max-width: 150px;
            }

            h5 {
                text-transform: capitalize;
                font-family: "roboto", "sans-serif";
                font-weight: 600;
                margin-top: 8px;
                margin-bottom: 8px;
                color: #ff881c;
            }
        }
    }

    .responsive-sec {
        h2 {
            @media(max-width:900px) {
                font-size: 28px;
                line-height: normal;
            }

        }

        h3 {
            @media(max-width:900px) {
                font-size: 24px;
                line-height: normal;
            }

        }

        &.disclaimer {
            p {
                color: rgba(0, 0, 0, 0.7);
                text-align: justify;
                font-size: 15px;
                line-height: 22px;
            }
        }

        &.pp {
            p {
                @media(max-width:900px) {
                    color: rgba(0, 0, 0, 0.7);
                    text-align: justify;
                    font-size: 15px;
                    line-height: 22px;
                }

            }
        }
    }

    // claim, complaint, contact
    .contactCard {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16) !important;
        border-radius: 10px;
        padding: 60px 20px 20px;
        max-width: 456px;
        margin: 45px 0 0 45px;
        position: relative;

        @media(max-width:899px) {
            margin-top: 120px;
            padding-bottom: 60px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 60px;
        }

        &:before {
            content: "";
            @include position(absolute, $top: -130px, $left: -45px);
            background: url("./../../public/images/call.svg")no-repeat 0 0/100%;
            width: 210px;
            height: 187px;

            @media(max-width:899px) {
                left: 0;
            }
        }

        &:after {
            content: "";
            @include position(absolute, $bottom: -50px, $right: -60px);
            background: url("./../../public/images/green-email.svg")no-repeat 0 0/100%;
            width: 100px;
            height: 80px;

            @media(max-width:1150px) {
                right: -12px;
            }

            @media(max-width:899px) {
                right: 0;
            }
        }

        .contactcard-Inner {
            h5 {
                margin-bottom: 8px;
                color: $primary;
            }

            p {
                a {
                    font-weight: 600;
                    color: $black;
                    word-break: break-all;

                    &:hover {
                        color: $secondary;
                    }
                }
            }
        }
    }

    .formBox {
        max-width: 430px;
        border: 1px solid $primary;
        border-radius: 15px;
        padding: 20px 15px;
        text-align: center;

        @media(max-width:899px) {
            margin: auto;
        }
    }

    .complaintCard {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16) !important;
        border-radius: 10px;
        padding: 20px;
        max-width: 456px;

        @media(max-width:899px) {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 40px;
        }

        .complaintcard-Inner {
            h5 {
                margin-bottom: 8px;
                color: $primary;
            }

            p {
                a {
                    font-weight: 600;
                    color: $black;
                    word-break: break-all;

                    &:hover {
                        color: $secondary;
                    }
                }
            }
        }
    }

    .complaintImgBox {
        max-width: 456px;
        margin-bottom: 40px;

        @media(max-width:899px) {
            margin-left: auto;
            margin-right: auto;
        }

        img {
            width: 100%;
        }
    }

    .contactBox {
        padding-top: 100px;
        background: url("./../../public/images/bg-line.svg") top center/80%;
        background-repeat: no-repeat;

        @media(max-width:899px) {
            padding-top: 50px;
        }

        h6 {
            margin-bottom: 8px;
            color: $primary;
            font-weight: 500;

            @media(max-width:899px) {
                font-size: 15px;
                line-height: normal;
            }
        }

        p {
            @media(max-width:899px) {
                font-size: 15px;
                line-height: normal;
            }

            span {
                font-weight: 600;
            }

            a {
                font-weight: 600;
                color: $black;
                word-break: break-all;

                &:hover {
                    color: $secondary;
                }
            }
        }

        .callCard {
            padding: 20px 20px 20px 90px;
            max-width: 428px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16) !important;
            border-radius: 10px;
            min-height: 165px;
            display: flex;
            align-items: center;
            position: relative;
            background: $white;

            @media(max-width:899px) {
                margin-left: 32px;
                margin-bottom: 50px;
                max-width: 100%;
                padding-left: 100px;
            }

            &:before {
                content: "";
                @include position(absolute, $top: 5px, $left: -140px);
                background: url("./../../public/images/call.svg")no-repeat 0 0/100%;
                width: 190px;
                height: 165px;

                @media(max-width:899px) {
                    left: -32px;
                    width: 140px;
                    height: 120px;
                    top: auto;
                    bottom: 0;
                }
            }
        }

        .mailCard {
            padding: 20px 90px 20px 20px;
            max-width: 470px;
            justify-content: end;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16) !important;
            border-radius: 10px;
            min-height: 165px;
            display: flex;
            align-items: center;
            position: relative;
            background: $white;

            @media(max-width:899px) {
                padding-right: 30px;
                margin-right: 32px;
                max-width: 100%;
            }

            &:before {
                content: "";
                @include position(absolute, $top: 5px, $right: -90px);
                background: url("./../../public/images/girl-mail.svg")no-repeat 0 0/100%;
                width: 170px;
                height: 200px;
                left: auto;

                @media(max-width:899px) {
                    right: -32px;
                    width: 94px;
                    height: 110px;
                }
            }

            .mailcardInner {
                text-align: right;

                @media(max-width:899px) {
                    text-align: left;
                    margin-right: auto;
                }
            }
        }

        .addressCard {
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16) !important;
            min-height: 240px;
            padding: 20px 75px 20px 15px;
            border-radius: 10px;
            margin-top: 100px;
            background: #fff;
            position: relative;
            max-width: 428px;
            display: flex;
            align-items: center;
            margin-left: auto;
            margin-right: auto;

            @media(max-width:899px) {
                min-height: 170px;
                max-width: 100%;
                margin-top: 50px;
            }

            &:before {
                content: "";
                @include position(absolute, $top: 5px, $right: 5px);
                background: url("./../../public/images/address.svg")no-repeat 0 0/100%;
                width: 70px;
                height: 200px;
                left: auto;

                @media(max-width:899px) {
                    width: 60px;
                    top: 2px;
                }
            }
        }
    }

    .contactFields {
        border: 1px solid $primary;
        border-radius: 15px;
        padding: 20px 0;
        text-align: center
    }

    // disclaimer
    .company_listing_list {
        padding-left: 20px;

        li {
            color: #000;
            @include fontSize(15px);
            font-weight: 500;
            line-height: 22px;

            .inner_list {
                padding-left: 15px;
                margin-bottom: 16px;

                li {
                    list-style-type: disc;
                    color: rgba(0, 0, 0, 0.7);
                    @include fontSize(13px);

                    a {
                        color: rgba(0, 0, 0, 0.7);

                        &:hover {
                            color: $primary;
                        }
                    }
                }
            }
        }
    }

    .beware_warning {
        border: 2px solid rgb(253, 2, 2);
        padding: 12px;
        border-radius: 8px;
    }

    .list-type {
        margin-left: 15px;
        margin-bottom: 10px;

        li {
            list-style-type: disc;
            color: rgba(0, 0, 0, 0.7);
            @include fontSize(15px);
            line-height: 21px;

            a {
                color: rgba(0, 0, 0, 0.7);

                &:hover {
                    color: $primary;
                }
            }
        }
    }
}

// mobile cms
.mCms-wrapper {
    padding-top: 66px;

    p {
        font-size: 15px;
        line-height: 22px;
        color: #212529;

        span {
            font-weight: 700;
        }
    }

    h3 {
        font-size: 28px;
    }

    h5 {
        line-height: normal;
    }

    .maboutTop-sec {
        img {
            max-width: 300px;
            width: 100%;
        }
    }

    .mwho-sec {
        .whoweBox {
            background: rgb(238, 238, 238);
            padding: 10px;
            border-radius: 10px;
            text-align: center;

            h4 {
                font-weight: 400;
                color: $primary;
                text-transform: uppercase;
            }

        }

        p {
            text-align: justify;
        }
    }

    .mvision-valueBox {
        .greyBox {
            background: rgb(234, 234, 234);
            padding: 20px 0px;
        }

        .vision-img {
            width: 100%;
            max-width: 180px;
        }
    }

    .asset_li {
        li {
            position: relative;
            padding-left: 24px;
            @include fontSize(15px);
            line-height: 22px;

            &::before {
                @include position(absolute, $top: 8px, $left: 10px);
                height: 6px;
                width: 6px;
                background: $primary;
                border-radius: 50%;
                content: '';
            }
        }
    }

    .mhumancapital_valueSec {
        .shadowBox {
            box-shadow: 0 4px 16px 0px rgba(0, 0, 0, 0.15) !important;
            border-radius: 10px;
            padding: 20px 10px;
            text-align: center;
            margin-top: 15px;

            img {
                width: 100%;
                max-width: 100px;
            }

            h5 {
                text-transform: capitalize;
                font-family: "roboto", "sans-serif";
                font-weight: 600;
                margin-top: 8px;
                margin-bottom: 8px;
                color: #ff881c;
            }
        }
    }
}