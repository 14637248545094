@import "./Mixins.scss";

.productWrapper {
  // padding-top: 90px;

  &.res-wrapper {
    @media (max-width: 900px) {
      padding-top: 66px;
    }
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.5);
    text-align: justify;

    span {
      font-weight: 700;
    }
  }

  h3 {
    font-size: 28px;
    color: #212529;
  }

  .formBox {
    background: #fff;
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: 1px solid rgba(255, 136, 28, 0.3);
    padding: 20px;
    position: relative;
    min-height: 250px;
    max-width: 750px;
    width: 100%;
    z-index: 9;
    margin: auto;

    @media (max-width: 700px) {
      padding: 24px 8px;
    }

    .topheading {
      text-align: center;
      border-bottom: 1px solid #dee2e6 !important;
      margin-bottom: 24px;

      h4 {
        color: #212529;
        margin-bottom: 4px;
      }

      span {
        font-size: 12px;
        display: block;
        margin-bottom: 16px;
      }
    }
  }

  .Note {
    margin: 20px 0 0;
  }

  .productpage_listpoints {
    margin-left: 18px;

    li {
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.5);
      text-align: justify;
      list-style-type: square;
    }
  }

  .productpage_OLpoints {
    margin-left: 18px;

    li {
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.5);
      text-align: justify;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        font-weight: 700;
        color: $secondary;
      }
    }
  }

  .pp_darkHeadlist {
    @extend .productpage_OLpoints;
    margin-left: 0;

    li {
      span {
        color: $black;
      }
    }
  }

  .insurer-partnersList {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    @media (max-width:599px) {
      gap: 12px;
      justify-content: center;
    }

    li {
      box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      border: 1px solid rgba(255, 136, 28, 0.3);
      padding: 12px 20px;
      text-align: center;

      @media (max-width:599px) {
        width: calc(50% - 6px);
      }

      img {
        height: 70px;
        object-fit: fill;
      }
    }
  }

  .comp_tabelWrapper {
    overflow-x: auto;
    .comparison_table {
      box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      border: 1px solid rgba(255, 136, 28, 0.3);
      overflow: hidden;
      width: 100%;
      min-width: 599px;

      th {
        font-size: 18px;

        @media (max-width:899px) {
          font-size: 16px;
        }
      }

      td {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.5);
      }

      th,
      td {
        padding: 12px;
        border-bottom: 1px solid rgba(255, 136, 28, 0.3);
        border-right: 1px solid rgba(255, 136, 28, 0.3);
        text-align: left;
        width: 20%;

        &:first-child {
          width: 40%;
          font-weight: 600;
        }
        &:last-child{
          border-right: none;
        }
      }

      tr {
        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }
  }
}