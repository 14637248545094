@import "./../../../SCSS/Mixins.scss";

.homepage-wrapper {
    // padding-top: 66px;

    .res-margin {
        @media(max-width:1199px) {
            margin-bottom: 40px;
        }
    }

    h2 {
        @media(max-width:1199px) {
            font-size: 28px;
            line-height: 40px;
        }
    }

    .product-section {
        margin-bottom: 100px;

        h1 {
            font-weight: 600;
            font-size: 45px;
        }

        h6 {
            color: $grey;
            font-weight: 400;
        }

        .product-sec-inner {
            min-height: 600px;
            background-image: url('../../../../public/images/headerbg.svg');
            background-position: center bottom;
            background-repeat: no-repeat;
            background-size: 100%;
            max-width: 1920px;
            margin: auto;
        }

        .ip_box {
            text-align: center;
            min-height: 160px;
            width: 140px;
            cursor: pointer;
            background: #fff;
            border-radius: 8px;
            box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.12);
            padding: 100px 10px 20px;
            position: relative;



            &:hover {
                background: $primary;
                color: $white;
            }

            p {
                font-size: 0.9375rem;
                font-weight: 500;
            }
        }

        $othericons: terminsurance, healthinsurance, carinsurance, bikeinsurance;
        $k: 0;

        @each $icons in $othericons {
            $bacRightP: $k * 80;
            $k: $k +1;

            .#{$icons} {
                &:before {
                    background: url("../../../../public/images/productIcons.svg") no-repeat -#{$bacRightP}px 0px/320px;
                    content: "";
                    @include position(absolute, $left: calc(50% - 40px), $top: 10px);
                    text-align: center;
                    overflow: hidden;
                    height: 80px;
                    width: 80px;
                }

                span {
                    display: block;
                }

            }
        }
    }

    .why-section {
        margin-bottom: 100px;

        ul {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 30px;

            .featureBox {
                width: calc((100% / 5) - 24px);
                @include border-radius(8px);
                box-shadow: 0px 0px 6px rgba(13, 25, 99, 0.11);
                padding: 24px 12px;
                min-height: 290px;
                text-align: center;
                min-width: 180px;
                flex: 1;

                h5 {
                    line-height: normal;
                    font-weight: 500;
                    margin-top: 30px;
                }

                img {
                    width: 100%;
                    max-height: 150px;
                }
            }
        }
    }

    .insured-section {
        margin-bottom: 100px;

        .worklist-list {
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;

            li {
                max-width: 300px;
                width: 100%;
                @include border-radius(8px);
                text-align: center;
                flex: 1;
                padding-top: 30px;
                @include fontSize(18px);
                line-height: 27px;
                font-weight: 500;
                position: relative;

                &:before {
                    content: '';
                    @include position(absolute, $top: 0px, $left: calc(50% - 15px));
                    height: 30px;
                    width: 30px;
                    background: url("../../../../public/images/listicon.svg") no-repeat 0px 0px/100%;

                }

                span {
                    display: block;
                    @include fontSize(14px);
                    line-height: 21px;
                    font-weight: 400;
                }
            }
        }

        .insured-bg {
            margin-top: 50px;

            img {
                width: 100%;
            }
        }
    }

    .partners-section {
        margin-bottom: 100px;

        .partners-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 30px;

            li {
                min-width: 130px;
                background: rgba(238, 238, 238, 1);
                border-radius: 8px;
                display: flex;
                justify-content: center;
                padding: 8px;
                width: calc((100% / 7) - 25.71px);

                img {
                    height: 60px;
                }
            }
        }
    }

    .subscribe-section {
        margin-bottom: 100px;

        .query-box {
            display: flex;
            gap: 16px;

            .subscribebox {
                // max-width: 400px;
                width: 100%;
                background: rgba($primary, 0.2);
                @include border-radius(8px !important);
                height: 60px;

                .inputField {
                    padding: 6px 12px;
                    background: transparent;
                    @include fontSize(18px);
                    height: 60px;
                    color: $primary;
                    border: none;
                    outline: none;
                }

            }

            .button_box {
                @include border-radius(8px !important);

                .btn {
                    background: $primary;
                    @include border-radius(8px);
                    color: $white;
                    @include fontSize(18px);
                    padding: 15px 35px;
                    border: none;
                    height: 100%;

                    &:hover {
                        background: #ffffff;
                        color: $primary;
                        outline: 1px solid $primary;
                        outline-offset: -1px;
                    }
                }
            }
        }

        .contactbox {
            position: relative;
            height: 400px;

            .supportBox {
                position: absolute;
                right: 12px;
                background: #f2f2f2;
                box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
                border-radius: 10px;
                text-align: left;
                padding: 20px;
                display: inline-block;
                min-width: 290px;

                span {
                    display: inline-block;

                    svg {
                        color: $primary;
                    }
                }

                h5 {
                    font-weight: 500;
                }

                a {
                    font-size: 16px;
                    color: $black;

                    &:hover {
                        color: $secondary;
                        opacity: 1;
                    }
                }

                &.email {
                    top: 30px;
                    transform: rotate(-10deg);
                }

                &.call {
                    bottom: 30px;
                    transform: rotate(10deg);
                }
            }
        }
    }
}

// form popup checkbox
.popup-Checkbox {
    .checkbox {
        justify-content: center;

        .MuiTypography-root {
            span {
                color: $primary;
            }
        }
    }
}

.self-selectField {
    .MuiToggleButtonGroup-root {
        height: 30px;

        button {
            font-size: 12px;
            line-height: normal;
            text-transform: capitalize;
            font-family: "Roboto", sans-serif !important;
            width: 60px;
            background: rgba(255, 243, 244, 1);
            font-weight: 400;
            color: rgba(79, 79, 79, 1);
            border: none;

            &.Mui-selected {
                background: $primary;
                color: $white;
            }
        }
    }
}