@import "../../SCSS/Mixins.scss";

.faq {
    margin-bottom: 8px;
    font-family: 'Roboto', 'sans-serif';

    .MuiPaper-root {
        background: transparent;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
        margin-bottom: 20px;
        padding: 5px 12px;

        &:last-child {
            border-bottom: none;
        }

        &:before {
            display: none;
        }
    }

    .MuiAccordionSummary-root {
        padding: 0;
        min-height: 44px;


        &.Mui-expanded {
            min-height: 44px;
            // max-height: 44px;
        }

        .MuiAccordionSummary-expandIconWrapper {
            color: $black;
            transform: none;

            svg {
                height: 30px;
                width: 30px;
            }
        }
    }

    .MuiAccordionSummary-content {
        margin: 12px 0px;

        &.Mui-expanded {
            margin: 12px 0px;
        }

        p {
            @include fontSize(20px);
            font-family: 'Roboto';
            line-height: 24px;
            font-weight: 700;
            color: $black;
            text-align: left;

            @media(max-width:899px) {
                @include fontSize(16px);
                line-height: 20px;
            }
        }
    }

    .MuiAccordionDetails-root {
        padding: 12px 0px 12px;

        p {
            @include fontSize(15px);
            font-family: 'Roboto';
            font-weight: 400;
            line-height: 20px;

        }
    }
}