@import "./../../../../SCSS/Mixins.scss";

.mnavbar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    min-height: 50px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: $white;
    padding: 6px 0px;
    z-index: 99;

    .hamburger-menu {
        cursor: pointer;
        position: relative;
        display: block;
        width: 30px;
        height: 30px;
        

        span {
            background: $black;
            @include position(absolute, $left: 0px, $top: 50%);
            display: block;
            @include fontSize(0px);
            width: 70%;
            height: 8%;

            &:before,
            &:after {
                background: $black;
                @include position(absolute, $left: 0px);
                transition: transform 0.3s;
                content: "";
                height: 100%;
            }

            &:before {
                transform: translateY(-300%);
                width: 50%;
            }

            &:after {
                transform: translateY(295%);
                width: 130%;
            }
        }

        &.active {
            span {
                background-color: transparent;

                &::before,
                &::after {
                    background-color: black;
                    top: 0;
                    left: 0px;
                    height: 1.5px;
                    width: 21px;
                }

                &::before {
                    transform: rotate(45deg);
                }

                &::after {
                    transform: rotate(-45deg);
                }
            }
        }
    }

    .login-link {
        display: inline-flex;
        color: #ff881c;
        font-size: 0.9375rem;
        font-weight: 700;
        padding: 3px 0px;
        svg {
            width: 15px;
            height: 15px;
            margin-right: 2px;
        }
    }
}

// sidemenu css
.MuiDrawer-root {
    .MuiPaper-root {
        min-width: 50%;
        max-width: 50%;

        .menuWrapper {
            position: relative;
            min-height: 100vh;
            .topbar {
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                min-height: 50px;
                display: flex;
                align-items: center;
                padding: 8px;

                .hamburger-menu {
                    position: relative;
                    display: block;
                    width: 30px;
                    height: 30px;
                    cursor: pointer;

                    span {
                        background: $black;
                        @include position(absolute, $left: 0px, $top: 50%);
                        display: block;
                        @include fontSize(0px);
                        width: 70%;
                        height: 8%;

                        &:before,
                        &:after {
                            background: $black;
                            @include position(absolute, $left: 0px);
                            transition: transform 0.3s;
                            content: "";
                            height: 100%;
                        }

                        &:before {
                            transform: translateY(-300%);
                            width: 50%;
                        }

                        &:after {
                            transform: translateY(295%);
                            width: 130%;
                        }
                    }

                    &.active {
                        span {
                            background-color: transparent;

                            &::before,
                            &::after {
                                background-color: black;
                                top: -1px;
                                left: 3px;
                                height: 1.5px;
                                width: 21px;
                            }

                            &::before {
                                transform: rotate(45deg);
                            }

                            &::after {
                                transform: rotate(-45deg);
                            }
                        }
                    }
                }
            }

            .menu-heading {
                padding: 8px 0px;
                font-size: 12px;
                line-height: 18px;
                border-bottom: 1px solid $lightRed;
                color: rgba(255, 243, 244, 1);
            }

            .dash-link {
                padding: 4px 0px;
                display: inline-flex;
                align-items: center;
                gap: 12px;

                img {
                    width: 30px;
                    height: 30px;
                }

                span {
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 500;
                    color: $white;
                }
            }

            .dash-link-login {
                padding: 4px 0px;
                display: inline-flex;
                align-items: center;
                gap: 12px;
                border: 1px solid $white;
                width: 100%;
                padding: 8px;
                border-radius: 100px;

                img {
                    width: 30px;
                    height: 30px;
                    border: 1px solid $white;
                    border-radius: 50%;
                }

                span {
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 500;
                    color: $white;
                }
            }

            .menu-section {
                padding: 12px;

                .menu-heading {
                    padding-top: 0;
                    color: $black;
                    background: rgba(0, 0, 0, 0.1);
                    padding: 6px 10px;
                    border-radius: 6px 6px 0px 0px;
                    margin-bottom: 10px;
                    font-size: 18px;
                    line-height: 27px
                }

                ul {
                    li {
                        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                        padding: 4px 0px;

                        .nav-link {
                            text-decoration: none;
                            color: #000;
                            @include fontSize(14px);
                            line-height: 21px;
                            font-weight: 400;
                            cursor: pointer;
                            padding: 4px 0px;
                            display: inline-flex;
                            align-items: center;
                            width: 100%;

                        }
                    }
                }
            }
        }
    }
}