@import "./../../../../SCSS/Mixins.scss";

.footer {
    .top-sec {
        background: $primary;
        padding-top: 40px;
        padding-bottom: 24px;

        h5 {
            color: $white;
            font-weight: 500;
        }

        p {
            color: $white;
        }

        .irdai-box {
            display: flex;
            align-items: center;
            gap: 16px;

            img {
                min-width: 40px;
            }

            p {
                color: $white;

                span {
                    font-weight: 700;
                }
            }
        }

        ul {
            display: flex;
            flex-direction: column;
            gap: 12px;

            li {
                a {
                    color: $white;
                    font-size: 15px;
                    line-height: 22px;
                    display: inline-block;

                    &:hover {
                        color: $secondary;
                        opacity: 1;
                    }
                }

                p {
                    color: $white;

                    span {
                        font-weight: 700;
                        color: $white;
                    }

                    a {
                        color: $white;
                        font-weight: 700;
                        word-break: break-all;
                    }
                }
            }

        }

        .social-icons {
            display: flex;
            gap: 16px;

            a {
                display: inline-block;

                // img {
                //     width: 22px;
                //     height: 22px;
                //     transition: transform 1s ease-in-out;

                //     &:hover {
                //         transform: scale(1.5);
                //         transition: transform 1s ease-in-out;
                //     }
                // }

                svg {
                    transition: transform 1s ease-in-out;

                    &:hover {
                        opacity: 1;
                        color: $secondary;
                        transform: scale(1.5);
                        transition: transform 1s ease-in-out;
                    }
                }
            }
        }

        .lightOrange-box {
            background: $primary;
            padding: 16px 0px;

            p {
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                color: $white;
            }
        }
    }
}