@import "./Mixins.scss";

h1 {
    @include fontSize(50px);
    line-height: 75px;
    font-weight: 600;

    span {
        font-weight: 400
    }
}

h2 {
    @include fontSize(40px);
    font-weight: 600;
    line-height: 60px;

    span {
        font-weight: 400
    }
}

h3 {
    @include fontSize(32px);
    font-weight: 600;
    line-height: 42px;
}

h4 {
    @include fontSize(24px);
    font-weight: 600;
    line-height: 36px;
}

h5 {
    @include fontSize(18px);
    font-weight: 600;
    line-height: 30px;
}

h6 {
    @include fontSize(16px);
    font-weight: 600;
    line-height: 24px;
}

p {
    @include fontSize(14px);
    font-weight: 400;
    line-height: 21px;
}

.mView {

    h1 {
        @include fontSize(50px);
        line-height: 75px;
        font-weight: 600;
    }

    h2 {
        @include fontSize(40px);
        font-weight: 600;
        line-height: 60px;
    }

    h3 {
        @include fontSize(32px);
        font-weight: 600;
        line-height: 42px;
    }

    h4 {
        @include fontSize(16px);
        font-weight: 600;
        line-height: 20px;
    }

    h5 {
        @include fontSize(14px);
        font-weight: 600;
        line-height: 20px;
    }

    h6 {
        @include fontSize(12px);
        font-weight: 600;
        line-height: 18px;
    }

    p {
        @include fontSize(10px);
        font-weight: 400;
        line-height: 16px;
    }
}